/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  function smoothScroll(target) {
    var speed = 500;

    if (target.length) {
      $('html,body').animate({
        scrollTop: target.offset().top
      }, speed);
    }
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $('.navbar-toggler').on('click', function (event) {
          var target = $($(this).data('target'));
          target.stop(true, false).fadeToggle();
        });

        if ($('.map').length) {
          $('[data-trigger="map"]').on({
            mouseenter: function (event) {
              $('#' + $(this).data('id')).addClass('active');
            },
            mouseleave: function (event) {
              $('#' + $(this).data('id')).removeClass('active');
            }
          });

          $('.map-region.enabled').on('click', function () {
            var target = $('[data-id="' + $(this).attr('id') + '"]');
            if (target.length && target.attr('href')) {
              location.href = target.attr('href');
            }
          }).removeClass('active');
        }

        if ($('.spotlight').length) {
          (function (spotlight) {
            var delay = 10000,
              items = $('.spotlight-item', spotlight),
              navItems = $('.spotlight-nav-btn', spotlight);

            if (items.length < 2) {
              return;
            }

            function loop() {
              var active = items.filter('.active'),
                index = items.index(active);

              var next = index + 1;
              if (next > items.length - 1) {
                next = 0;
              }

              var activate = function (all) {
                all.removeClass('active').eq(next).addClass('active');
              };

              activate(items);
              activate(navItems);
            }

            var interval = setInterval(loop, delay);

            navItems.on('click', function (event) {
              var index = navItems.index($(this));

              clearInterval(interval);

              navItems.removeClass('active');
              items.removeClass('active').eq(index).addClass('active');
              $(this).addClass('active');

              interval = setInterval(loop, delay);
            });
          })($('.spotlight'));
        }

        if ($('.contact, .contact-us, .page-template-template-contacts-php').length) {
          $('.contact, .contact-us, .page-template-template-contacts-php').on('click', '.content a[href^=tel]', function (event) {
            window.ga && ga.loaded && ga('send', 'event', 'link', 'contact', 'phone', $(this).attr('href'));
          }).on('click', '.content a[href^=mailto]', function (event) {
            window.ga && ga.loaded && ga('send', 'event', 'link', 'contact', 'email', $(this).attr('href'));
          });
        }

        if ($('.tax-region, .single-breeder').length) {
          $('.tax-region, .single-breeder').on('click', '.content a[href^=tel]', function (event) {
            window.ga && ga.loaded && ga('send', 'event', 'link', 'breeder', 'phone', $(this).attr('href'));
          }).on('click', '.content a[href^=mailto]', function (event) {
            window.ga && ga.loaded && ga('send', 'event', 'link', 'breeder', 'email', $(this).attr('href'));
          });
        }

        if ($('.home-actions').length) {
          $('.home-actions').on('click', '.btn', function (event) {
            window.ga && ga.loaded && ga('send', 'event', 'link', 'home', 'action', $(this).attr('href'));
          });
        }

        if ($('.spotlight-link').length) {
          $('.spotlight-link').on('click', 'a', function (event) {
            window.ga && ga.loaded && ga('send', 'event', 'link', 'spotlight', 'more', $(this).attr('href'));
          });
        }

        if ($('.header-social').length) {
          $('.header-social').on('click', 'a', function (event) {
            window.ga && ga.loaded && ga('send', 'event', 'link', 'social', 'facebook');
          });
        }

        $('.scroll').on('click', function (event) {
          event.preventDefault();
          smoothScroll($($(this).attr('href')));
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
